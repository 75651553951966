// @flow

import React, { Suspense, useEffect, useState, useRef } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import "./index.less";
import mudeyGreyIcon from "~images/mudeyGreyNew.jpg";
import "react-notifications-component/dist/theme.css";
import downArrow from "~images/downArrow.svg";
// import Intercom from "react-intercom";
import upArrow from "~images/upArrow.svg";
import { Steps } from "antd";
import ReactNotification from "react-notifications-component";
import KKgarantias from "../../images/mudey_kk_garantia.svg"; //this image is used in journey steps
import mudeyGarantiaNewIcon from "~images/mudey_kk_garantia_d.svg";
// import BottomBrand from "../../images/bottombrand.png";
// import BottomBrand from "../../images/KKFooterLogo.svg";
// HOC
import routes from "../../routes";
import { useLocation } from "react-router";
import { titleManager } from "../../utils/titleManager";
import useIsMobileWindow from "../../utils/isMobile";
import HelpCrunch from "~components/helpcrunch";
import CustomerlyChat from "~components/customerly";
import { CustomerlyProvider } from "react-live-chat-customerly";
const appID = process.env.REACT_APP_INTERCOM_APP_ID;

function App(props: PropsType) {
  const customerlyId = process.env.REACT_APP_CUSTOMERLY_ID;
  const history = useHistory();
  const ref = useRef();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const isMobile = useIsMobileWindow();
  useEffect(() => {
    titleManager(location);
  }, [location]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        ref &&
          ref.current &&
          ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 300);
    }
    if (show) {
      ref &&
        ref.current &&
        ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [show]);
  const renderSteps = () => {
    const { pathname } = location;
    let step = -1;

    if (pathname.indexOf("/simulacao") > -1) step = 0;
    else if (
      pathname.indexOf("/resultados/pacotes") > -1 ||
      pathname.indexOf("/resultados/comparar") > -1
    )
      step = 1;
    else if (
      pathname.indexOf("/compra/resumo") > -1 ||
      pathname.indexOf("/resultados/summary") > -1
    )
      step = 2;
    else if (pathname.indexOf("/resultados/payment") > -1) step = 2;

    return step >= 0 ? (
      <Steps current={step} progressDot size="small" style={{ color: "white" }}>
        <Steps.Step title="Questionário" />
        <Steps.Step title="Resultados" />
        <Steps.Step title="Compra" />
      </Steps>
    ) : (
      <div />
    );
  };

  const scrollToElement = (element: any, height: any = 100) => {
    let takeRef = document.querySelector(element).offsetTop - height;
    window.scroll({ top: takeRef, behavior: "smooth" });
  };
  return (
    <React.Fragment>
      <ReactNotification />
      {location.pathname != "/" ? (
        <header className="header">
          <div className="container">
            <div className="header-wrapper">
              {/* <div style={{ paddingTop: "12%" }}> */}
              <a
                href={process.env.REACT_APP_HP_URL}
                className="header-logo-container"
              >
                <img
                  alt="Mudey"
                  src={KKgarantias}
                  title="Mudey"
                  className="header-logo"
                />
              </a>
              {/* </div> */}
              {console.log(location.pathname)}
              {renderSteps()}
            </div>
          </div>
        </header>
      ) : (
        <header
          className="header"
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: "999",
            background: "#56b7e0",
          }}
        >
          <div className="container">
            <div className="header-wrapper-homepage">
              {/* <div style={{ paddingTop: "12%" }}> */}
              <a
                href={process.env.REACT_APP_HP_URL}
                className="header-logo-container"
              >
                <img
                  alt="Mudey"
                  src={mudeyGarantiaNewIcon}
                  title="Mudey"
                  className="header-logo"
                />
              </a>
              <div className="nav-links">
                <p onClick={() => scrollToElement(".section-two")}>
                  Coberturas
                </p>
                <p onClick={() => scrollToElement(".section-three")}>
                  Como funciona
                </p>
                <p onClick={() => scrollToElement(".section-four")}>
                  Vantagens
                </p>
                <p onClick={() => scrollToElement(".section-five", 170)}>
                  FAQs
                </p>
                <p onClick={() => scrollToElement(".section-six")}>Contactos</p>
              </div>
              {/* </div> */}
              <button
                className="purchase-button"
                onClick={() => history.push("/seguro-garantia/simulacao")}
              >
                Simula já
              </button>
            </div>
          </div>
        </header>
      )}

      <main
        className="main"
        // className={location.pathname.includes("payment") ? "full-main" : "main"}
      >
        <div
          // className="container"
          className={
            ["/", "/home"].includes(location.pathname)
              ? "full-container-kk"
              : "container"
          }
        >
          <Suspense fallback={<React.Fragment />}>
            <Switch>
              {/* <Redirect exact from="/" to="/seguro-garantia/simulacao" /> */}
              {routes.map((route: Object, index: number) => {
                return (
                  <Route
                    component={route.component}
                    exact={route.exact}
                    key={index}
                    name={route.name}
                    path={route.path}
                    title={route.title}
                  />
                );
              })}
              <Redirect to="/not-found" />
            </Switch>
          </Suspense>
        </div>
      </main>
      <footer className="footer">
        <div className="container">
          <div className="footer-wrapper">
            <div className="footer-wrapper-iob">
              <img
                alt="Mudey Logo"
                className="footer-logo"
                src={mudeyGreyIcon}
                title="Mudey Logo"
              />
              <span>
                &copy; {new Date().getFullYear()} MUDEY Todos os direitos
                reservados.
              </span>
            </div>
            <div className="footer-wrapper-col">
              <p style={{ textAlign: "center" }}>
                A MUDEY é uma mediadora de seguros autorizada pela ASF com o
                número 420558967, no ramo vida e não-vida.
              </p>
            </div>
            <p className="main-text" onClick={() => setShow(!show)}>
              Informação Legal
              {!show ? (
                <img src={downArrow} className="arrow-img-down" />
              ) : (
                <img src={upArrow} className="arrow-img-up" />
              )}
            </p>
            {show && (
              <>
                <div className="hyperlinks-wrapper">
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/politica-de-privacidade/"
                    >
                      Politica de Privacidade
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/politica-cookies/"
                    >
                      Política de Cookies
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/termos-de-utilizacao/"
                    >
                      Termos de Uso
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/politica-tratamento/"
                    >
                      Política de Tratamento
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/aviso-privacidade/"
                    >
                      Aviso de Privacidade
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/politica-pagamentos/"
                    >
                      Regulamento sobre Pagamentos
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/gestao-de-reclamacoes/"
                    >
                      Gestão de Reclamações
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/wp-content/uploads/2022/02/Ficha-Informativa-V9.pdf"
                      // ref={ref}
                    >
                      Deveres Especiais de Informação
                    </a>
                  </span>
                </div>
                <div className="hyperlinks-end-div" ref={ref} />
              </>
            )}
          </div>
          {/* <Intercom appID={appID} /> */}
          {/* <HelpCrunch /> */}
          <CustomerlyProvider appId={customerlyId}>
            <CustomerlyChat />
          </CustomerlyProvider>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default App;

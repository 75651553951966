// Auth Routes (Login required)
import React from "react";

// Public Routes
const NotFoundPage = React.lazy(() =>
  import("../containers/state-page/not-found")
);

const KKJourney = React.lazy(() => import("../containers/journeys/KK"));
const KuantoResults = React.lazy(() => import("../containers/results/KK"));
const KKSummary = React.lazy(() => import("../containers/summary/KK"));
const ThankYouKK = React.lazy(() => import("../containers/thank-you/KK"));
const PaymentCredit = React.lazy(() => import("../containers/payment/KK"));
const HomePage = React.lazy(() => import("../containers/home"));
const HomepageNew = React.lazy(() => import("../containers/home-new"));
// const HomepageSectionFive = React.lazy(() =>
//   import("../containers/home-section-five")
// );

const routes = [
  {
    path: "/not-found",
    exact: true,
    name: "NotFoundPage",
    component: NotFoundPage,
    authRequired: false,
  },
  {
    path: "/seguro-garantia/simulacao/:categoria?",
    exact: true,
    name: "KKJourney",
    component: KKJourney,
    authRequired: false,
  },
  {
    path: "/seguro-garantia/resultados/pacotes/:categoria?",
    exact: true,
    name: "KuantoResults",
    component: KuantoResults,
    authRequired: false,
  },
  {
    path: "/seguro-garantia/resultados/summary/:orderId?",
    exact: true,
    name: "KKSummary",
    component: KKSummary,
    authRequired: false,
  },
  {
    path: "/seguro-garantia/compra/obrigado/:orderId?",
    exact: true,
    name: "ThankYouKK",
    component: ThankYouKK,
    authRequired: false,
  },

  {
    path: "/seguro-garantia/resultados/payment/:orderId?",
    exact: true,
    name: "PaymentCredit",
    component: PaymentCredit,
    authRequired: false,
  },
  // {
  //   path: "/",
  //   exact: true,
  //   name: "HomePage",
  //   component: HomePage,
  //   authRequired: false,
  // },
  {
    path: "/",
    exact: true,
    name: "HomePage",
    component: HomepageNew,
    authRequired: false,
  },
  // {
  //   path: "/home",
  //   exact: true,
  //   name: "HomePageSectionFive",
  //   component: HomepageSectionFive,
  //   authRequired: false,
  // },
];

export default routes;

import { useEffect } from "react";
import { useCustomerly } from "react-live-chat-customerly";
const CustomerlyChat = (props: any) => {
  // const { userData } = props;
  const { load } = useCustomerly();

  // Automatically load the chat when the component mounts
  useEffect(() => {
    load();
  }, []);

  return null;
};

export default CustomerlyChat;

// @flow
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";

import TagManager from "react-gtm-module";
// import firebase from 'firebase/app'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { persistor, store } from "./store";

import ScrollToTop from "./ScrollToTop";
import App from "./containers/app";

import "./resources/less/index.less";
// import 'firebase/auth'
// import 'firebase/database'
import "sanitize.css/sanitize.css";
import "./i18n";

import { ApolloProvider } from "@apollo/react-hooks";
import client from "./services/api";

if (process.env.REACT_APP_GTM_KEY.length != 0) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_KEY,
  };
  TagManager.initialize(tagManagerArgs);
}

export const history = createBrowserHistory();
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

const target = document.querySelector("#root");

if (target) {
  render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ApolloProvider client={client}>
          <Router history={history}>
            <ScrollToTop>
              {/* <Elements stripe={stripePromise}> */}
              <App client={client} />
              {/* </Elements> */}
            </ScrollToTop>
          </Router>
        </ApolloProvider>
      </PersistGate>
    </Provider>,
    target
  );
}

// @flow
import Cookies from 'js-cookie'
import * as actionTypes from './actions'
import { composeWithDevTools } from 'redux-devtools-extension'
const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'

const token = Cookies.get('MUDEY_token', { domain: domain }) || null
const uuid = Cookies.get('MUDEY_uuid', { domain: domain }) || null
const email = Cookies.get('MUDEY_email', { domain: domain }) || null
const name = Cookies.get('MUDEY_name', { domain: domain }) || null

const user = token
  ? {
      email,
      name,
      uuid
    }
  : null

const initialState = {
  user: user,
  token: token,
  isAuthenticated: token ? true : false,
  status: 'out',
  kkData:[],
  categoryList:[]
}

const reducer = (state: Object = initialState, action: Object) => {
  const { payload, type, birthdate } = action
  switch (type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        user: payload.user,
        status: 'in'
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        status: 'out'
      }
    case actionTypes.KK_DATA:
      return{
        ...state,
        kkData:payload

      }
    case actionTypes.CATEGORY_LIST:
      return{
        ...state,
        categoryList:payload

      }   
    default:
      return state
  }
}

export default reducer

// @flow
import { useState, useEffect } from "react";
let id = null;
const titles = {
  "seguro-garantia": {
    simulacao: "KK | Garantia | Questionário",
    resultados: {
      pacotes: "KK | Garantia | Resultados",
      summary: "KK | Garantia | Pagamento",
      payment: "KK | Garantia | Pagamento",
    },
    compra: {
      pagamento: "KK | Garantia | Pagamento",
      obrigado: "KK | Garantia | Compra",
      beneficiarios: "",
      "thank-you": "KK | Garantia | Compra",
      "payment-failure": "",
      "confirmacao-compra": "",
      // payment: "KK | Garantia | Pagamento",
    },
  },
  // "":"sdf"
};

export const titleManager = (location: any): void => {
  if (location && location.pathname) {
    // id = localStorage.getItem("journeyID");
    // titles["seguro-iob"][id] = "IOB | Garantia | Questionário";
    const root = location.pathname.split("/")[1] || "";
    const firstKey = location.pathname.split("/")[2] || "";
    const lastKey = location.pathname.split("/")[3] || "";
    console.log(firstKey);
    const title = () => {
      if (lastKey) {
        return (
          titles[root][firstKey][lastKey] ||
          // ||null
          "KK | Garantia | Questionário"
        );
      }
      if (!lastKey && firstKey) {
        return titles[root][firstKey] || null;
      }
      return null;
    };

    const docTitle = title();

    if (docTitle) {
      document.title = docTitle;
    } else {
      document.title = "KK | Garantias";
    }
  } else {
    document.title = "KK";
  }
};
